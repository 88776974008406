import { VoiceData } from '#/data/voice';

export const WEBVIEW_READY = 'flutterInAppWebViewPlatformReady';

export enum WebViewEvent {
  toastFromWeb = 'toastFromWeb',
  toastFromApp = 'toastFromApp',
  pushFromApp = 'pushFromApp',
  incommingCallFromApp = 'incommingCallFromApp',
  onPausedFromApp = 'onPausedFromApp',
  writePropertyFromWeb = 'writePropertyFromWeb',
  readPropertyFromWeb = 'readPropertyFromWeb',
  removePropertyFromWeb = 'removePropertyFromWeb',
  resetPropertyFromWeb = 'resetPropertyFromWeb',
  wakeLockFromWeb = 'wakeLockFromWeb',
  getDeviceInfoFromApp = 'getDeviceInfoFromApp',
  getAppVersionFromApp = 'getAppVersionFromApp',
  getFcmTokenFromApp = 'getFcmTokenFromApp',
  setExceptPopupUrl = 'setExceptPopupUrl',
  setThemeFromWeb = 'setThemeFromWeb',
  setDataFromApp = 'setDataFromApp',
  getDataFromWeb = 'getDataFromWeb',
  removeVoiceDataFromApp = 'removeVoiceDataFromApp',
  getRecentDataFromWeb = 'getRecentDataFromWeb',
  deleteAllFilesDataFromApp = 'deleteAllFilesDataFromApp'
}

export enum WebViewProperty {
  AUTH_SCROLL_ENABLED = 'AUTH_SCROLL_ENABLED'
}

export enum WebViewPropertyEnabled {
  TRUE = 'true',
  FALSE = 'false'
}

export const OAUTH_URLS = [
  'nid.naver.com',
  'accounts.kakao.com',
  'kauth.kakao.com',
  'appleid.apple.com'
];

interface WebViewToastParams {
  level?: 'success' | 'warning' | 'error';
  text: string;
  timeout?: number;
}

export function showAppToast({
  text,
  level = 'success',
  timeout = 3
}: WebViewToastParams) {
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler(
      WebViewEvent.toastFromWeb,
      level,
      text,
      timeout
    );
  } else {
    console.log(WebViewEvent.toastFromWeb, level, text, timeout);
  }
}

export async function writeProperty(
  key: string,
  value: string
): Promise<string> {
  if (window.flutter_inappwebview) {
    return window.flutter_inappwebview.callHandler(
      WebViewEvent.writePropertyFromWeb,
      key,
      value
    );
  } else {
    console.log(WebViewEvent.writePropertyFromWeb, key, value);
    return Promise.resolve('not supported');
  }
}

export async function readProperty(key: string): Promise<string> {
  if (window.flutter_inappwebview) {
    return window.flutter_inappwebview.callHandler(
      WebViewEvent.readPropertyFromWeb,
      key
    );
  } else {
    console.log(WebViewEvent.readPropertyFromWeb, key);
    return Promise.resolve('not supported');
  }
}

export async function resetProperties(): Promise<boolean> {
  if (window.flutter_inappwebview) {
    return window.flutter_inappwebview.callHandler(
      WebViewEvent.resetPropertyFromWeb
    );
  } else {
    console.log(WebViewEvent.resetPropertyFromWeb);
    return Promise.resolve(true);
  }
}

export async function setWakeLock(enable: boolean): Promise<boolean> {
  if (window.flutter_inappwebview) {
    return window.flutter_inappwebview.callHandler(
      WebViewEvent.wakeLockFromWeb,
      enable
    );
  } else {
    console.log(WebViewEvent.wakeLockFromWeb);
    return Promise.resolve(true);
  }
}

export const showWebToast = (e: any) => {
  console.log(e.detail);
};

export async function getDeviceInfo(): Promise<Record<string, string>> {
  if (window.flutter_inappwebview) {
    return window.flutter_inappwebview.callHandler(
      WebViewEvent.getDeviceInfoFromApp
    );
  } else {
    console.log(WebViewEvent.getDeviceInfoFromApp);
    return Promise.resolve({});
  }
}

export async function getFcmToken(): Promise<string> {
  if (window.flutter_inappwebview) {
    return window.flutter_inappwebview.callHandler(
      WebViewEvent.getFcmTokenFromApp
    );
  } else {
    console.log(WebViewEvent.getFcmTokenFromApp);
    return Promise.resolve('fcm token');
  }
}

export async function getAppVersion(): Promise<string> {
  if (window.flutter_inappwebview) {
    return window.flutter_inappwebview.callHandler(
      WebViewEvent.getAppVersionFromApp
    );
  } else {
    console.log(WebViewEvent.getAppVersionFromApp);
    return Promise.resolve('version');
  }
}

export function setExceptPopupUrl(urls: string[]) {
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler(
      WebViewEvent.setExceptPopupUrl,
      urls
    );
  } else {
    console.log(WebViewEvent.setExceptPopupUrl, urls);
  }
}

export function setThemeFromWeb(theme: string) {
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler(
      WebViewEvent.setThemeFromWeb,
      theme
    );
  } else {
    console.log(WebViewEvent.setThemeFromWeb, theme);
  }
}

export function isAndroid() {
  if (typeof window === 'undefined') {
    return false;
  }
  var userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf('android') > -1;
}

export function isIOS() {
  if (typeof window === 'undefined') {
    return false;
  }
  const userAgent = navigator.userAgent.toLowerCase();
  return /iphone|ipod|ipad/.test(userAgent);
}

export const readAutoScrollEnabled = async () => {
  const autoScrollEnabled = await readProperty(
    WebViewProperty.AUTH_SCROLL_ENABLED
  );

  return autoScrollEnabled !== WebViewPropertyEnabled.FALSE;
};

export const writeAutoScrollEnabled = async (enable: boolean) => {
  await writeProperty(
    WebViewProperty.AUTH_SCROLL_ENABLED,
    enable ? WebViewPropertyEnabled.TRUE : WebViewPropertyEnabled.FALSE
  );
};

export async function getDataFromWeb(key: string) {
  if (window.flutter_inappwebview) {
    return window.flutter_inappwebview.callHandler(
      WebViewEvent.getDataFromWeb,
      key
    );
  } else {
    console.log(WebViewEvent.getDataFromWeb);
    return Promise.resolve([]);
  }
}

export async function setDataFromApp(voiceData: VoiceData) {
  try {
    // voiceData의 blob을 base64 문자열로 대체
    const updatedVoiceData = {
      ...voiceData
    };

    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler(
        WebViewEvent.setDataFromApp,
        updatedVoiceData
      );
    } else {
      console.log(WebViewEvent.setDataFromApp, updatedVoiceData);
    }
  } catch (error) {
    console.error('Blob 변환 오류:', error);
  }
}

export async function removeVoiceDataFromApp(key: string) {
  try {
    // Blob 데이터를 base64로 변환
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler(
        WebViewEvent.removeVoiceDataFromApp,
        key
      );
    } else {
      console.log(WebViewEvent.removeVoiceDataFromApp, key);
    }
  } catch (error) {
    console.error('Blob 변환 오류:', error);
  }
}

export async function getRecentDataFromWeb() {
  if (window.flutter_inappwebview) {
    return window.flutter_inappwebview.callHandler(
      WebViewEvent.getRecentDataFromWeb
    );
  } else {
    console.log(WebViewEvent.getRecentDataFromWeb);
    return Promise.resolve([]);
  }
}

export async function deleteAllFilesDataFromApp() {
  if (window.flutter_inappwebview) {
    return window.flutter_inappwebview.callHandler(
      WebViewEvent.deleteAllFilesDataFromApp
    );
  } else {
    console.log(WebViewEvent.deleteAllFilesDataFromApp);
    return Promise.resolve([]);
  }
}
