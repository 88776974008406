import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { add, subHours } from 'date-fns';
import { ReadonlyURLSearchParams } from 'next/navigation';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

const DIGIT_EXPRESSION = /^\d+$/;
export const isDigit = (character: string) => {
  return character && DIGIT_EXPRESSION.test(character);
};

export const delay = (ms: number, callback?: () => void) => {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve(ms);
      callback && callback();
    }, ms)
  );
};

export function convertEnumToArray(e: { [s: string]: string }) {
  return Object.keys(e).map((k) => ({
    id: k,
    text: e[k as keyof typeof e]
  }));
}

export interface PaginationResult {
  pagination: {
    offset: number;
    limit: number;
    column: string | undefined;
    order: 'asc' | 'desc' | undefined;
  };
  searchable: any;
  filterable: any;
  rangable: any;
}

export const getPaginationParams = ({
  searchParams,
  searchable = [],
  filterable = [],
  rangable = []
}: {
  searchParams: {
    [key: string]: string | string[] | undefined;
  };
  searchable?: ReadonlyArray<string>;
  filterable?: ReadonlyArray<string>;
  rangable?: ReadonlyArray<string>;
}): PaginationResult => {
  const { page, per_page, sort } = searchParams;
  // Number of items per page
  const limit = typeof per_page === 'string' ? parseInt(per_page) : 10;
  // Number of items to skip
  const offset =
    typeof page === 'string'
      ? parseInt(page) > 0
        ? (parseInt(page) - 1) * limit
        : 0
      : 0;
  // Column and order to sort by
  // Spliting the sort string by "." to get the column and order
  // Example: "title.desc" => ["title", "desc"]
  const [column, order] =
    typeof sort === 'string'
      ? (sort.split('.') as [string | undefined, 'asc' | 'desc' | undefined])
      : [];

  return {
    pagination: {
      offset,
      limit,
      column,
      order
    },
    searchable: searchable.reduce((p: any, f: string) => {
      const val = searchParams[f];
      p[f] = typeof val === 'string' ? (val.split(',') as string[]) : [];
      return p;
    }, {}),
    filterable: filterable.reduce((p: any, f: string) => {
      const val = searchParams[f];
      p[f] = typeof val === 'string' ? (val.split('.') as string[]) : [];
      return p;
    }, {}),
    rangable: rangable.reduce((p: any, f: string) => {
      const val = searchParams[f] as string;
      const fromTo = val?.split('|') ?? [];
      const isValid =
        fromTo.length === 2 &&
        isDateFormat(fromTo[0]) &&
        isDateFormat(fromTo[1]);
      p[f] = isValid
        ? {
            from: subHours(new Date(fromTo[0]), 9),
            to: subHours(
              add(new Date(fromTo[1]), {
                days: 1
              }),
              9
            )
          }
        : null;
      return p;
    }, {})
  };
};

export function setCookie(name: string, value: string, seconds: number) {
  var exdate = new Date();
  exdate.setSeconds(exdate.getSeconds() + seconds);
  var cookie_value =
    escape(value) +
    (seconds == 0 ? '' : ';  path=/; expires=' + exdate.toUTCString());
  document.cookie = name + '=' + cookie_value;
}

export const isDateFormat = (d: string | null) => {
  if (!d) {
    return false;
  }
  return /^\d{4}-\d{2}-\d{2}$/.test(d);
};

export const splitArray = (input: any[], spacing: number = 100) => {
  var output = [];

  for (var i = 0; i < input.length; i += spacing) {
    output.push(input.slice(i, i + spacing));
  }

  return output;
};

export const createQueryString = (
  searchParams: ReadonlyURLSearchParams,
  params: Record<string, string | number | null>
) => {
  const newSearchParams = new URLSearchParams(searchParams?.toString());

  for (const [key, value] of Object.entries(params)) {
    if (value === null) {
      newSearchParams.delete(key);
    } else {
      newSearchParams.set(key, String(value));
    }
  }

  return newSearchParams.toString();
};

interface DownloadFileParams {
  url: string;
  fileName: string;
  downloadFileName: string;
}

export const DownloadFile = async ({
  url,
  fileName,
  downloadFileName
}: DownloadFileParams) => {
  let ftch = null;
  try {
    ftch = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({ fileName })
    });

    if (ftch.status === 200) {
      const fileBlob = await ftch.blob();

      var link = document.createElement('a');
      const url = window.URL.createObjectURL(fileBlob);
      link.href = url;
      link.download = downloadFileName;
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }
  } catch (e) {
    console.log({ message: e, status: 400 });
  }
  return ftch;
};
